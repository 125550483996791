<template>
  <div>
    <div class="row page-title align-items-center">
      <div v-if="plan && plan.id && !isSalePlan" class="col-sm-12">
        <div class="d-flex align-items-center">
          <a
            :href="encodeURI(`${location.origin}/order?plan=${plan.id}`)"
            target="_blank"
          >
            <strong>{{
              encodeURI(`${location.origin}/order?plan=${plan.id}`)
            }}</strong>
          </a>
          <span v-b-tooltip.hover title="Copy this link" class="copy-icon pl-2" @click="copyLink">
            <feather type="copy" />
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div v-if="!loadingPlan">
              <ValidationObserver
                v-if="plan"
                v-slot="{ handleSubmit, invalid, touched }"
                ref="updateForm"
              >
                <form
                  class="authentication-form"
                  @submit.prevent="handleSubmit(updatePlan)"
                >
                  <div class="row">
                    <div class="col-md-6">
                      <TextInput
                        v-model="plan.name"
                        name="name"
                        rules="required"
                        label="Plan Name"
                      />
                    </div>
                    <div class="col-md-6">
                      <TextInput
                        v-model="plan.desc"
                        name="name"
                        rules="required"
                        label="Plan Desc."
                      />
                    </div>
                    <div class="col-md-6">
                      <TextInput
                        v-model="plan.amount"
                        name="price"
                        rules="required"
                        label="Price ($)"
                        :readonly="true"
                      />
                    </div>
                    <div class="col-md-6">
                      <TextInput
                        v-model="plan.trial_period_days"
                        name="trial_period_days"
                        label="Trial period days"
                        type="number"
                      />
                    </div>
                    <div class="col-md-4">
                      <TextInput
                        v-model="plan.free_numbers"
                        name="free_numbers"
                        label="Free Numbers"
                        type="number"
                      />
                    </div>
                    <div class="col-md-4">
                      <TextInput
                        v-model="plan.free_messages"
                        name="free_messages"
                        label="Free Text Messages / Month"
                        type="number"
                      />
                    </div>
                    <div class="col-md-4">
                      <TextInput
                        v-model="plan.free_calls"
                        name="free_calls"
                        label="Free Call Minutes / Month"
                        type="number"
                      />
                    </div>
                    <div v-if="!isSalePlan" class="col-md-6">
                      <div class="form-group">
                        <label>Sale plan</label>
                        <select v-model="plan.sale_plan" class="form-control">
                          <option value="">No sale plan</option>
                          <option
                            v-for="otherPlan in plans"
                            :key="otherPlan.id"
                            :value="otherPlan.id"
                            >{{ otherPlan.name }}</option
                          >
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <TextInput
                          v-model="plan.interval"
                          name="trial_period_days"
                          label="Interval"
                          :disabled="true"
                        />
                      </div>
                    </div>
                    <div v-if="isSalePlan" class="col-md-6">
                      <TextInput
                        v-model="plan.video"
                        name="video"
                        label="Video"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <label>Has Upfront?</label>
                      <div>
                        <b-form-checkbox
                          v-model="plan.has_upfront"
                          name="check-button"
                          switch
                          inline
                          class="mt-2"
                        >
                        </b-form-checkbox>
                      </div>
                    </div>
                    <div v-if="plan.has_upfront" class="col-md-6">
                      <TextInput
                        v-model="plan.upfront_price"
                        name="Upfront Price"
                        rules="required|min_value:1"
                        label="Upfront ($)"
                        type="number"
                      />
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-md-12">
                      <label>Description</label>
                      <editor v-model="plan.description" :api-key="tinyMCEKey" :init="{ height: 250 }" :toolbar="customToolbar" :plugins="plugins"/>
                    </div>
                  </div>
                  <h5 class="h5 mt-3">Features</h5>
                  <table class="table table-bordered meta-table">
                    <thead class="thead-light">
                      <tr>
                        <th>Key</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in plan.features" :key="item.key">
                        <td>
                          <strong>{{item.key}}</strong>
                        </td>
                        <td>
                          <TextInput
                            v-if="amountKeys.includes(item.key)"
                            v-model="item.value"
                            name="value"
                          />
                          <b-form-checkbox
                            v-else
                            v-model="item.value"
                            name="check-button"
                            switch
                            inline
                            class="mt-2"
                          >
                          </b-form-checkbox>
                        </td>
                      </tr>
                      <tr v-if="!isSalePlan">
                        <td>
                          <strong>Visible</strong>
                        </td>
                        <td>
                          <b-form-checkbox
                            v-model="plan.visible"
                            name="check-button"
                            switch
                            inline
                            class="mt-2"
                          >
                          </b-form-checkbox>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="row mt-5 mb-4">
                    <div class="col-md-6">
                      <label>Do you want to apply this change to all existing customers?</label>
                      <div>
                        <b-form-checkbox
                          v-model="plan.apply_existings"
                          name="check-button"
                          switch
                          inline
                          class="mt-2"
                        >
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>
                  <b-form-group>
                    <b-button
                      variant="primary"
                      :disabled="loadingUpdate || (invalid && touched)"
                      type="submit"
                    >
                      <b-spinner v-if="loadingUpdate" small />
                      <span v-else>Update</span>
                    </b-button>
                  </b-form-group>
                </form>
              </ValidationObserver>
            </div>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Editor from '@tinymce/tinymce-vue'

export default {
  components: {
    'editor': Editor
  },

  data() {
    return {
      location: location,
      loadingPlan: false,
      plan: {
        metadata: [
          { key: 'Number of Businesses', value: '' },
        ],
      },
      loadingUpdate: false,
      newFeature: {
        key: '',
        value: '',
      },
      tinyMCEKey: process.env.VUE_APP_TINYMCE_KEY,
      plugins: 'imagetools image autolink link media lists',
      customToolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | image media',
      amountKeys: ['Number of Businesses',],
    }
  },

  computed: {
    plans() {
      let plans = this.$store.getters['subscription/allPlans'] || []

      return plans.filter(
        (p) => this.plan && this.plan.id !== p.id && p.is_sale_plan
      )
    },

    isSalePlan() {
      if (!this.plan || (this.plan && !this.plan.features)) return false

      let salePlan = this.plan.features.filter((f) => f.key === 'Sales Plan')[0]
      return salePlan && salePlan.value
    },
    
    user() {
      return this.$store.getters['auth/user']
    },
  },

  mounted() {
    if (!this.$store.getters['subscription/allPlans']) {
      this.$store.dispatch('subscription/getAllPlans')
    }

    this.getPlan()
  },

  methods: {
    copyLink() {
      var tempInput = document.createElement("input");
      tempInput.value = `${location.origin}/order?plan=${this.plan.id}`;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);

      Vue.$toast.open({
        message: 'Copied',
        type: 'success',
        duration: 5000,
      })
    },

    updatePlan() {
      this.loadingUpdate = true
      this.$store
        .dispatch('subscription/updatePlan', this.plan)
        .then(() => {
          this.loadingUpdate = false
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.updateForm.setErrors(err.response.data.errors)
          }
          this.loadingUpdate = false
        })
    },

    getPlan() {
      this.loadingPlan = true

      this.$store
        .dispatch('subscription/findPlan', this.$route.params.id)
        .then((plan) => {
          this.plan = plan
          this.loadingPlan = false
        })
        .catch(() => {
          this.$router.push({ name: 'admin.agencies.edit' })
          this.loadingPlan = false
        })
    },

    addMetadata() {
      this.plan.metadata.push(this.newFeature)
      this.newFeature = { key: '', value: '' }
    },

    removeMetadata(index) {
      this.plan.metadata.splice(index, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
  .meta-table {
    td, th {
      vertical-align: middle;
    }
    .form-group {
      margin-bottom: 0!important;
    }
  }
  .copy-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i {
      width: 16px;
      color: #5369f8;
    }
  }
</style>